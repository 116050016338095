import React from 'react';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import style from '../../components/Globals/Styles.module.css';
import styles from '../Contact/ContactPage.module.css';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';
import InputField from '../../components/Input/InputField';
import Checkbox from '../../components/Input/Checkbox';
import { ReactComponent as MailIcon } from '../../icons/mail-01.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as UserPlusIcon } from '../../icons/user-plus-01.svg';

const ContactPage = () => {
  const breadcrumbs = [{ label: 'Service & contact', path: '/contact' }];

  return (
    <BasePage>
      <PageHeader 
        title="Neem contact met ons op" 
        supportingText="Heeft u vragen? Wij zijn er om u te helpen." 
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}
        extraBottomPadding={true}
      />

{/* Blokken sectie */}
<div className={styles.blocksContainer}>
  <div className={styles.contactBlock}>
    {/* Iconblok */}
    <div className={styles.iconWrapper}>
      <div className={styles.iconBox}>
        <MailIcon className={styles.icon} />
      </div>
    </div>
    {/* Titel en tekst */}
    <div className={styles.textWrapper}>
      <h3 className={style.textXlSemibold}>Mail ons</h3>
      <p className={style.textMdRegular}>Reactie binnen 5 werkdagen.</p>
    </div>
    {/* Knop */}
    <div className={styles.buttonWrapper}>
      <ButtonWithIcon className="alertLink">
        <a href="mailto:info@datamarketplace.com" className={styles.link}>info@datamarketplace.com</a>
      </ButtonWithIcon>
    </div>
  </div>

  <div className={styles.contactBlock}>
    {/* Iconblok */}
    <div className={styles.iconWrapper}>
      <div className={styles.iconBox}>
        <PhoneIcon className={styles.icon} />
      </div>
    </div>
    {/* Titel en tekst */}
    <div className={styles.textWrapper}>
      <h3 className={style.textXlSemibold}>Bel ons</h3>
      <p className={style.textMdRegular}>Maandag tot vrijdag van 9:00 tot 17:00.</p>
    </div>
    {/* Knop */}
    <div className={styles.buttonWrapper}>
      <ButtonWithIcon className="alertLink">
        <a href="tel:+15550000000" className={styles.link}>+1 (555) 000-0000</a>
      </ButtonWithIcon>
    </div>
  </div>

  <div className={styles.contactBlock}>
    {/* Iconblok */}
    <div className={styles.iconWrapper}>
      <div className={styles.iconBox}>
        <UserPlusIcon className={styles.icon} />
      </div>
    </div>
    {/* Titel en tekst */}
    <div className={styles.textWrapper}>
      <h3 className={style.textXlSemibold}>Nieuw contact aanmelden</h3>
      <p className={style.textMdRegular}>
        Meld een nieuw contact aan waar u een leveringsovereenkomst of verkoopcontract mee wil aangaan.
      </p>
    </div>
    {/* Knop */}
    <div className={styles.buttonWrapper}>
      <ButtonWithIcon className="alertLink">
        <a href="/contact-aanmelden" className={styles.link}>Nieuw contact aanmelden</a>
      </ButtonWithIcon>
    </div>
  </div>
</div>



      {/* Contactformulier */}
<div className={styles.formContainer}>
  <div className={styles.formHeader}>
    <h2 className={style.displaySmSemibold}>Heeft u een vraag?</h2>
    <p className={`${style.textXlRegular} ${styles.customContactText}`}>
      Neem contact met ons op.
    </p>
  </div>

  <form className={styles.contactForm}>
    {/* Inputvelden voor naam */}
    <div className={styles.inputRow}>
      <InputField label="Voornaam" placeholder=" " />
      <InputField label="Achternaam" placeholder=" " />
    </div>
    {/* Inputvelden voor email en telefoon */}
    <div className={styles.inputRow}>
      <InputField label="E-mail" placeholder=" " />
    </div>
    <div className={styles.inputRow}>
      <InputField label="Telefoonnummer" placeholder=" " />
    </div>
    {/* Textarea voor bericht */}
    <div className={styles.inputRow}>
      <div className={styles.textAreaContainer}>
        <InputField 
          label="Bericht" 
          placeholder="Schrijf uw bericht..." 
          isTextarea={true} 
          rows={5} 
        />
      </div>
    </div>
    {/* Checkbox */}
    <div className={styles.checkboxRow}>
      <Checkbox
        label="U gaat akkoord met onze"
        linkLabel="Algemene Voorwaarden"
        linkPath="/algemene-voorwaarden"
      />
    </div>
    {/* Verstuurknop */}
    <ButtonWithIcon
      className="buttonPrimary2XL"
    >
      Verstuur uw bericht
    </ButtonWithIcon>
  </form>
</div>

    </BasePage>
  );
};

export default ContactPage;
