import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BasicTable.module.css';
import style from '../Globals/Styles.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import SearchFilterBar from '../SearchFilterBar/SearchFilterBar';
import Badge from '../Badge/Badge';

const BasicTable = ({
  rows,
  headerTitle = 'Title',
  columns = [],
  error = false,
  isSortable = false,
  searchPlaceholder = "Zoeken",
  showRadioButtons = false,
  radioOptions = [],
  emptyStateConfig = {},
  errorStateConfig = {},
}) => {
  const [sortedRows, setSortedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(columns.length > 0 ? columns[0].field : 'title');
  const [selectedRadio, setSelectedRadio] = useState('');
  const navigate = useNavigate();

  const defaultEmptyStateConfig = {
    title: 'Geen resultaten gevonden',
    subtitle: 'Er zijn geen items om weer te geven.',
    buttons: [],
  };

  const defaultErrorStateConfig = {
    title: 'Er is iets mis gegaan...',
    subtitle: 'We hebben problemen met het laden van de gegevens.',
    buttons: [],
  };

  const mergedEmptyStateConfig = { ...defaultEmptyStateConfig, ...emptyStateConfig };
  const mergedErrorStateConfig = { ...defaultErrorStateConfig, ...errorStateConfig };

  const filteredAndSortedRows = useMemo(() => {
    let filtered = rows.filter(row =>
      columns.some(column => {
        const value = row[column.field];
        return typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );

    if (isSortable) {
      filtered.sort((a, b) =>
        sortOrder === 'asc'
          ? (a[sortColumn] || '').localeCompare(b[sortColumn] || '')
          : (b[sortColumn] || '').localeCompare(a[sortColumn] || '')
      );
    }

    return filtered;
  }, [rows, searchTerm, sortColumn, sortOrder, isSortable, columns]);

  useEffect(() => {
    setSortedRows(filteredAndSortedRows);
  }, [filteredAndSortedRows]);

  const handleRowClick = (row) => {
    if (row.url) {
      navigate(row.url);
    }
  };

  const handleSort = (field) => {
    if (sortColumn === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(field);
      setSortOrder('asc');
    }
  };

  const renderState = ({ icon, title, subtitle, buttons }) => (
    <div className={styles.emptyStateContainer}>
      <div className={styles.iconCircle}>{icon}</div>
      <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>{title}</p>
      <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>{subtitle}</p>
      <div className={styles.buttonContainer}>
        {buttons?.map((button, idx) => (
          <ButtonWithIcon
            key={idx}
            className="buttonPrimaryXL"
            onClick={button.onClick}
          >
            {button.label}
          </ButtonWithIcon>
        ))}
      </div>
    </div>
  );

  const renderEmptyState = () =>
    renderState({
      icon: <SearchIcon className={styles.searchIcon} />,
      title: mergedEmptyStateConfig.title,
      subtitle: mergedEmptyStateConfig.subtitle,
      buttons: mergedEmptyStateConfig.button ? [mergedEmptyStateConfig.button.primary] : [],
    });

  const renderErrorState = () =>
    renderState({
      icon: <AlertIcon className={styles.alertIcon} />,
      title: mergedErrorStateConfig.title,
      subtitle: mergedErrorStateConfig.subtitle,
      buttons: mergedErrorStateConfig.buttons,
    });

  const getSortIcon = (field) => {
    if (sortColumn === field) {
      return sortOrder === 'asc' ? (
        <ChevronUpIcon className={`${styles.sortIcon} ${styles.activeSortIcon}`} />
      ) : (
        <ChevronDownIcon className={`${styles.sortIcon} ${styles.activeSortIcon}`} />
      );
    } else {
      return <ChevronUpIcon className={styles.sortIcon} style={{ opacity: 0.5 }} />;
    }
  };

  return (
    <div>
      <SearchFilterBar
        onSearch={setSearchTerm}
        searchPlaceholder={searchPlaceholder}
        sortOptions={[{ value: 'title', label: 'Sorteren op titel' }]}
        showRadioButtons={showRadioButtons}
        radioOptions={radioOptions}
        selectedRadio={selectedRadio}
        onRadioChange={setSelectedRadio}
      />
      <div className={styles.tableContainer}>
        {error ? (
          renderErrorState()
        ) : sortedRows.length === 0 ? (
          renderEmptyState()
        ) : (
          <table className={styles.table}>
<thead className={styles.tableHeader}>
  <tr>
    {columns.map((col, index) => (
      <th
        key={index}
        className={`${style.textXsMedium} ${styles.headerCell}`}
        style={{ width: col.width }}
        onClick={isSortable ? () => handleSort(col.field) : null}
      >
        {col.header}
        {isSortable && getSortIcon(col.field)}
      </th>
    ))}
  </tr>
</thead>
<tbody>
  {sortedRows.map((row, index) => (
    <tr key={index} className={styles.tableRow} onClick={() => handleRowClick(row)}>
      {columns.map((col, colIndex) => (
        <td
          key={colIndex}
          className={styles.cell}
          style={{
            width: col.width,
            color: col.type === 'badge' ? undefined : col.textColor, // Geen kleur voor badges
          }}
        >
          {col.type === 'badge' ? (
            <Badge status={row[col.field]} />
          ) : (
            <div className={style.textSmRegular}>
              {row[col.field]}
            </div>
          )}
        </td>
      ))}
    </tr>
  ))}
</tbody>

          </table>
        )}
      </div>
    </div>
  );
};

export default BasicTable;
