import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';
import Dropdown from '../../components/Input/Dropdown';
import InputField from '../../components/Input/InputField';
import Alert from '../../components/Feedback/Alert';
import style from '../../components/Globals/Styles.module.css';
import FileUpload from '../../components/FileUpload/FileUpload';
import ProgressSteps from '../../components/ProgressSteps/ProgressSteps';
import styles from './VerkoopContract.module.css';

const NieuwVerkoopovereenkomstPage = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: 'Mijn verkoopcontracten', path: '/verkoopcontracten' },
    { label: 'Nieuw verkoopcontract', path: '/verkoopcontracten/nieuw' },
  ];

  /*Data wat via de API binnen gaat komen*/
  const opdrachtOptions = [
    { value: 'Wooncorporatie A', label: 'Wooncorporatie A' },
    { value: 'Gemeente ABC', label: 'Gemeente ABC' },
  ];

  /*Data wat via de API binnen gaat komen*/
  const woningtypeOptions = [
    { value: 'Woningtype A', label: 'Woningtype A' },
    { value: 'Woningtype B', label: 'Woningtype B' },
  ];

  /*Stappen*/
  const steps = ['Gegevens invullen', 'Gegevens controleren'];
  const currentStep = 0;

  /*Informatievelden*/
  const [formData, setFormData] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem('formData'));
    return savedData || {
    };
  });

  /*Upload logica*/
  const handleFileUpload = (field, files) => {
    const fileDetails = files.map((file) => ({
      name: file.name,
      size: (file.size / 1024 / 1024).toFixed(2),
      type: file.type,
    }));

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: fileDetails,
      };
      localStorage.setItem('formData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleClick = () => {
  };

  const handleNextStep = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
    console.log('Navigating to next step with formData:', formData);
    navigate('/verkoopcontracten/nieuw/controle');
  };

  return (
    <BasePage backgroundColor="#F9FAFB">
      <PageHeader
        title="Concept verkoopcontract opstellen"
        breadcrumbs={breadcrumbs}
        variant="basic"
        showTabs={false}
        showDivider={false}
        extraBottomPadding={true}
      />

      <div className={styles.stepsContainer}>
        <ProgressSteps steps={steps} currentStep={currentStep} />
      </div>

      {/* Blok 1 met formulier type inhoud */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionInstructions}`}>Instructies</h2>
        <p className={style.textSmRegular}>U wil een verkoopcontract aangaan met een opdrachtgever waar u straks onderdelen voor wil bestellen voor een woningtype.
          Vul hieronder de gegevens is in met wie u een contract wil opstellen, voor welk woningtype en op welke locatie. Als u de gegevens heeft ingevuld dan wordt
          het verstuurd naar de opdrachtgever met de opdracht om de gegevens te controleren en het contract te ondertekenen. </p>
        <ButtonWithIcon
          iconName="right"
          iconPosition="left"
          className="alertLink"
          onClick={() => console.log('Link geklikt!')}
        >
          Nog vragen? Kijk hier voor meer informatie
        </ButtonWithIcon>
      </div>

      {/* Blok 2: Overeenkomstgegevens */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Overeenkomstgegevens</h2>

        {/* Opdrachtgever */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdrachtgever</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Met welke opdrachtgever wil u een overeenkomst aangaan?"
              options={opdrachtOptions}
              value={formData.opdrachtgever}
              onChange={(value) => handleInputChange('opdrachtgever', value)}
              placeholder="Kies een opdrachtgever"
            />
          </div>
        </div>

        {/* Alert */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <Alert
              type="info"
              title="Kunt u een contact niet vinden?"
              description="Als het contact waar u naar opzoek bent niet in de lijst staat, mogelijk heeft het contact nog geen toegang tot de DataMarketPlace. Nodig uw contact uit om deel te nemen door het invullen van hun gegevens"
              linkText="Nieuwe contact aanmelden"
              linkUrl="https://marlin-scx.com/"
              onClick={handleClick}
            />
          </div>
        </div>

        {/* Woningtype */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Woningtype</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Welk woningtype wil u opnemen in de overeenkomst?"
              options={woningtypeOptions}
              value={formData.woningtype}
              onChange={(value) => handleInputChange('woningtype', value)}
              placeholder="Kies een woningtype"
            />
          </div>
        </div>

        {/* Aantal woningen */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Aantal woningen</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Om hoeveel woningen gaat het in totaal?"
              type="number"
              value={formData.aantalWoningen}
              onChange={(e) => handleInputChange('aantalWoningen', e.target.value)}
              placeholder=""
            />
          </div>
        </div>
      </div>

      {/* Blok 3: Opdracht informatie */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Opdracht informatie</h2>

        {/* Opdracht naam */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdracht naam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wat is de naam van uw opdracht?"
              placeholder=" "
              value={formData.opdrachtNaam}
              onChange={(e) => handleInputChange('opdrachtNaam', e.target.value)}
            />
          </div>
        </div>

        {/* Budget */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Budget</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wat is het totale budget?"
              placeholder="€ 0,00"
              value={formData.budget}
              onChange={(e) => handleInputChange('budget', e.target.value)}
            />
          </div>
        </div>

        {/* Startdatum */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Startdatum</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wanneer start de opdracht?"
              placeholder=" "
              value={formData.startdatum}
              onChange={(e) => handleInputChange('startdatum', e.target.value)}
            />
          </div>
        </div>

        {/* Verwachte opleverdatum */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Verwachte opleverdatum</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wanneer wordt de opdracht opgeleverd?"
              placeholder=" "
              value={formData.opleverdatum}
              onChange={(e) => handleInputChange('opleverdatum', e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Blok 4: Beheerder informatie (optioneel) */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Beheerder informatie (optioneel)</h2>

        {/* Beheerder */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Beheerder</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wie is de beheerder namens de opdrachtgever?"
              placeholder=""
              value={formData.beheerder}
              onChange={(e) => handleInputChange('beheerder', e.target.value)}
            />
          </div>
        </div>

        {/* Contactpersoon */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Contactpersoon</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wie is uw contactpersoon?"
              placeholder=""
              value={formData.contactpersoon}
              onChange={(e) => handleInputChange('contactpersoon', e.target.value)}
            />
          </div>
        </div>

        {/* Adres */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Straatnaam"
              placeholder=""
              value={formData.adres}
              onChange={(e) => handleInputChange('adres', e.target.value)}
            />
          </div>
        </div>

        {/* Postcode, Huisnummer, Toevoeging */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField
                label="Postcode"
                placeholder=""
                value={formData.postcode}
                onChange={(e) => handleInputChange('postcode', e.target.value)}
              />
              <InputField
                label="Huisnummer"
                placeholder=""
                value={formData.huisnummer}
                onChange={(e) => handleInputChange('huisnummer', e.target.value)}
              />
              <InputField
                label="Toevoeging"
                placeholder=""
                value={formData.toevoeging}
                onChange={(e) => handleInputChange('toevoeging', e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Stad */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Stad"
              placeholder=""
              value={formData.stad}
              onChange={(e) => handleInputChange('stad', e.target.value)}
            />
          </div>
        </div>

        {/* Land */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Land"
              placeholder=""
              value={formData.land}
              onChange={(e) => handleInputChange('land', e.target.value)}
            />
          </div>
        </div>

        {/* Telefoonnummer */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Telefoonnummer</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wat is het telefoonnummer?"
              placeholder=""
              value={formData.telefoonnummer}
              onChange={(e) => handleInputChange('telefoonnummer', e.target.value)}
            />
          </div>
        </div>

        {/* E-mail */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>E-mail</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Wat is de email?"
              placeholder=""
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Blok 5: Bouwlocatie van project */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Bouwlocatie</h2>

        {/* Bedrijfsnaam */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bedrijfsnaam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              // label="Wat is de naam van het bedrijf?"
              placeholder=""
              value={formData.bedrijfsnaam}
              onChange={(e) => handleInputChange('bedrijfsnaam', e.target.value)}
            />
          </div>
        </div>

        {/* Adres */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Straatnaam"
              placeholder=""
              value={formData.adres}
              onChange={(e) => handleInputChange('adres', e.target.value)}
            />
          </div>
        </div>

        {/* Postcode, Huisnummer, Toevoeging */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField
                label="Postcode"
                placeholder=""
                value={formData.postcode}
                onChange={(e) => handleInputChange('postcode', e.target.value)}
              />
              <InputField
                label="Huisnummer"
                placeholder=""
                value={formData.huisnummer}
                onChange={(e) => handleInputChange('huisnummer', e.target.value)}
              />
              <InputField
                label="Toevoeging"
                placeholder=""
                value={formData.toevoeging}
                onChange={(e) => handleInputChange('toevoeging', e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Stad */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Stad"
              placeholder=""
              value={formData.stad}
              onChange={(e) => handleInputChange('stad', e.target.value)}
            />
          </div>
        </div>

        {/* Land */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Land"
              placeholder=""
              value={formData.land}
              onChange={(e) => handleInputChange('land', e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Blok 6: Assemblage locatie */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Assemblage locatie</h2>

        {/* Bedrijfsnaam */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bedrijfsnaam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              // label="Wat is de naam van het bedrijf?"
              placeholder=""
              value={formData.assemblageBedrijfsnaam}
              onChange={(e) => handleInputChange('assemblageBedrijfsnaam', e.target.value)}
            />
          </div>
        </div>

        {/* Adres */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              label="Straatnaam"
              placeholder=""
              value={formData.assemblageAdres}
              onChange={(e) => handleInputChange('assemblageAdres', e.target.value)}
            />
          </div>
        </div>

        {/* Postcode, Huisnummer, Toevoeging */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField
                label="Postcode"
                placeholder=""
                value={formData.assemblagePostcode}
                onChange={(e) => handleInputChange('assemblagePostcode', e.target.value)}
              />
              <InputField
                label="Huisnummer"
                placeholder=""
                value={formData.assemblageHuisnummer}
                onChange={(e) => handleInputChange('assemblageHuisnummer', e.target.value)}
              />
              <InputField
                label="Toevoeging"
                placeholder=""
                value={formData.assemblageToevoeging}
                onChange={(e) => handleInputChange('assemblageToevoeging', e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Stad */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Stad"
              placeholder=""
              value={formData.assemblageStad}
              onChange={(e) => handleInputChange('assemblageStad', e.target.value)}
            />
          </div>
        </div>

        {/* Land */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField
              label="Land"
              placeholder=""
              value={formData.assemblageLand}
              onChange={(e) => handleInputChange('assemblageLand', e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Blok 7: Woningspecificaties */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Woningspecificaties</h2>

        {/* Optie voor woning */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Optie voor woning</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Welke optie kiest de opdrachtgever voor de woning?"
              options={woningtypeOptions}
              value={formData.woningOptie}
              onChange={(value) => handleInputChange('woningOptie', value)}
              placeholder="Kies een optie"
            />
          </div>
        </div>
      </div>

      {/* Blok 8: Overige informatie */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Overige informatie</h2>

        {/* Opmerkingen */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opmerkingen</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              // label="Vul hier eventuele opmerkingen in"
              placeholder=""
              value={formData.opmerkingen}
              onChange={(e) => handleInputChange('opmerkingen', e.target.value)}
            />
          </div>
        </div>

        {/* Risicoanalyse */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Risicoanalyse</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField
              // label="Geef details over de risicoanalyse"
              placeholder=""
              value={formData.risicoAnalyse}
              onChange={(e) => handleInputChange('risicoanalyse', e.target.value)}
            />
          </div>
        </div>
      </div>


      {/* Blok 9: Documenten */}
      <div className={styles.formBlock}>
        <h2 className={`${style.textXlSemibold} ${styles.sectionTitle}`}>Documenten</h2>

        {/* Projectplan */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Projectplan</label>
          </div>
          <div className={styles.inputColumn}>
            <FileUpload
              onUpload={(files) =>
                handleFileUpload('projectplan', files || [])
              }
            />
          </div>
        </div>

        {/* Bouwplan */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bouwplan</label>
          </div>
          <div className={styles.inputColumn}>
            <FileUpload
              onUpload={(files) =>
                handleFileUpload('bouwplan', files || [])
              }
            />
          </div>
        </div>

        {/* Vergunningen */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Vergunningen</label>
          </div>
          <div className={styles.inputColumn}>
            <FileUpload
              onUpload={(files) =>
                handleFileUpload('vergunningen', files || [])
              }
            />
          </div>
        </div>

        {/* Risicoanalyse */}
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Risicoanalyse</label>
          </div>
          <div className={styles.inputColumn}>
            <FileUpload
              onUpload={(files) =>
                handleFileUpload('risicoanalyse', files || [])
              }
            />
          </div>
        </div>
      </div>

      {/* Knop rechts uitgelijnd onder het laatste blok */}
      <div className={styles.nieuwVerkoopContractButtonContainer}>
        <ButtonWithIcon
          className="buttonPrimary2XL"
          onClick={handleNextStep} // Voeg de onClick toe
        >
          Verder
        </ButtonWithIcon>
      </div>

    </BasePage>
  );
};

export default NieuwVerkoopovereenkomstPage;
