import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FileUpload.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const FileUpload = ({ onUpload }) => {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        onUpload([...files, ...newFiles]); // Update bestanden naar de oudercomponent
    };

    const handleRemoveFile = (fileName) => {
        const updatedFiles = files.filter(file => file.name !== fileName);
        setFiles(updatedFiles);
        onUpload(updatedFiles); // Geef de bijgewerkte lijst door aan de oudercomponent
    };

    const handleUploadClick = () => {
        document.getElementById('file-upload').click();
    };

    return (
        <div className={styles.uploadContainer}>
            <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
                className={styles.hiddenInput}
            />
            <ButtonWithIcon
                iconName="upload"
                iconPosition="left"
                className="uploadButton"
                onClick={handleUploadClick}
            >
                Upload bestand
            </ButtonWithIcon>

            {files.map((file, index) => (
                <div key={index} className={styles.fileDisplay}>
                    <span className={styles.fileIcon}></span>
                    <span className={styles.fileName}>{file.name}</span>
                    <span className={styles.fileSize}>
                        {(file.size / 1024 / 1024).toFixed(2)} MB
                    </span>
                    <button
                        onClick={() => handleRemoveFile(file.name)}
                        className={styles.removeButton}
                    >
                        <img src="/icons/x.svg" alt="Verwijder bestand" />
                    </button>
                </div>
            ))}
        </div>
    );
};

FileUpload.propTypes = {
    onUpload: PropTypes.func.isRequired, // Zorg dat onUpload verplicht is
};

export default FileUpload;
