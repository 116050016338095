import React from 'react';
import styles from './RadioButton.module.css';

const RadioButton = ({ label, checked, onChange, value, name, description, disabled }) => {
  const uniqueId = `radio-${name}-${value}`;

  return (
    <div className={`${styles.radioContainer} ${disabled ? styles.disabled : ''}`}>
      <input
        id={uniqueId}
        type="radio"
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disabled}
        className={styles.radioInput}
      />
      <label htmlFor={uniqueId} className={styles.radioLabel}>
        <span className={styles.radioCheckmark}></span>
        <span className={styles.radioText}>{label}</span>
        {description && <div className={styles.radioDescription}>{description}</div>}
      </label>
    </div>
  );
};

export default RadioButton;
