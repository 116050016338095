import React from 'react';
import styles from './ProgressSteps.module.css';
import style from '../../components/Globals/Styles.module.css';

const ProgressSteps = ({ steps, currentStep }) => {
  return (
    <div className={styles.stepsContainer}>
      {steps.map((label, index) => (
        <div key={index} className={styles.stepContainer}>
          {index > 0 && <div className={`${styles.line} ${styles.leftLine}`} />}

          <div
            className={`${styles.circle} ${
              index < currentStep
                ? `${styles.complete}`
                : index === currentStep
                ? styles.current
                : styles.incomplete
            }`}
          >
            {index < currentStep ? '✔' : <div className={styles.innerDot} />}
          </div>

          {index < steps.length - 1 && <div className={`${styles.line} ${styles.rightLine}`} />}

          <div
            className={`${styles.stepLabel} ${style.textSmSemibold} ${
              index === currentStep ? styles.activeLabel : styles.inactiveLabel
            }`}
          >
            {label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressSteps;
