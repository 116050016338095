import React, { useState } from 'react';
import styles from './DropdownTable.module.css';
import style from '../Globals/Styles.module.css';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import Badge from '../Badge/Badge';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import SearchFilterBar from '../SearchFilterBar/SearchFilterBar';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';

const DropdownTable = ({
  rows,
  error = false,
  showRadioButtons = false,
  radioOptions = [],
  selectedRadio,
  onRadioChange,
  searchPlaceholder = "Zoeken...",
  emptyStateConfig = {},
  errorStateConfig = {},
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleRowClick = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Filter rows op basis van zoekterm
  const filteredRows = rows.filter((row) => {
    const isInTitle = row.title?.toLowerCase().includes(searchTerm.toLowerCase());
    const isInDetails = row.details?.some((detail) =>
      ['productName', 'articleNumber', 'supplierName']
        .map((key) => detail[key]?.toLowerCase().includes(searchTerm.toLowerCase()))
        .some(Boolean)
    );

    return isInTitle || isInDetails;
  });

  // Lege staat configuratie
  const defaultEmptyStateConfig = {
    title: 'Geen resultaten gevonden',
    subtitle: 'Probeer een andere zoekterm of voeg nieuwe gegevens toe.',
    button: null,
  };

  const mergedEmptyStateConfig = { ...defaultEmptyStateConfig, ...emptyStateConfig };

  // Error staat configuratie
  const defaultErrorStateConfig = {
    title: 'Er is iets mis gegaan...',
    subtitle: 'Probeer het opnieuw of neem contact met ons op.',
    button: null,
  };

  const mergedErrorStateConfig = { ...defaultErrorStateConfig, ...errorStateConfig };

  const renderState = ({ icon, title, subtitle, button }) => (
    <div className={styles.stateContainer}>
      <div className={styles.iconCircle}>{icon}</div>
      <p className={`${style.textMdSemibold} ${styles.stateTitle}`}>{title}</p>
      <p className={`${style.textSmRegular} ${styles.stateSubtitle}`}>{subtitle}</p>
      {button && (
        <ButtonWithIcon
          className="buttonPrimaryXL"
          onClick={button.onClick}
        >
          {button.label}
        </ButtonWithIcon>
      )}
    </div>
  );

  const renderEmptyState = () =>
    renderState({
      icon: <SearchIcon className={styles.searchIcon} />,
      title: mergedEmptyStateConfig.title,
      subtitle: mergedEmptyStateConfig.subtitle,
      button: mergedEmptyStateConfig.button,
    });

  const renderErrorState = () =>
    renderState({
      icon: <AlertIcon className={styles.alertIcon} />,
      title: mergedErrorStateConfig.title,
      subtitle: mergedErrorStateConfig.subtitle,
      button: mergedErrorStateConfig.button,
    });

  return (
    <div>
      <SearchFilterBar
        onSearch={setSearchTerm}
        searchPlaceholder={searchPlaceholder}
        showRadioButtons={showRadioButtons}
        radioOptions={radioOptions}
        selectedRadio={selectedRadio}
        onRadioChange={onRadioChange}
      />
      <div className={styles.tableContainer}>
        {error ? (
          renderErrorState()
        ) : filteredRows.length === 0 ? (
          renderEmptyState()
        ) : (
          <table className={styles.table}>
            <tbody>
              {filteredRows.map((row, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={styles.tableRow}
                    onClick={() => handleRowClick(index)}
                  >
                    <td className={styles.cell}>
                      <div className={style.textMdSemibold} style={{ color: '#101828' }}>
                        {row.title}
                      </div>
                    </td>
                    <td className={styles.cellIcon}>
                      {expandedRows.includes(index) ? (
                        <ChevronUpIcon className={styles.chevronIcon} />
                      ) : (
                        <ChevronDownIcon className={styles.chevronIcon} />
                      )}
                    </td>
                  </tr>
                  {expandedRows.includes(index) && (
  <tr className={styles.expandedRow}>
    <td colSpan={2} className={styles.expandedContent}>
      {row.details?.length > 0 ? (
        <table className={styles.subTable}>
          <thead>
            <tr>
              <th className={style.textSmRegular}>Leveringsovereenkomst</th>
              <th className={style.textSmRegular}>Overeenkomstnummer</th>
              <th className={style.textSmRegular}>Bekijk overeenkomst</th>
              <th className={style.textSmRegular}>Status</th>
            </tr>
          </thead>
          <tbody>
            {row.details.map((detail, idx) => (
              <tr key={idx} className={styles.subTableRow}>
                <td>
                  <div className={style.textSmBold}>{detail.productName}</div>
                  <div className={style.textSmRegular}>Artikelnummer: {detail.articleNumber}</div>
                  <div className={style.textSmRegular}>Leverancier: {detail.supplierName}</div>
                </td>
                <td className={style.textSmRegular}>{detail.contractNumber}</td>
                <td>
                  <ButtonWithIcon className="tableLink">
                    {detail.linkText || 'Bekijk overeenkomst'}
                  </ButtonWithIcon>
                </td>
                <td>
                  <Badge status={detail.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.emptyDetailsContainer}>
          <SearchIcon className={styles.emptyStateIcon} />
          <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>
            Geen details beschikbaar
          </p>
          <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>
            Deze rij heeft geen details om weer te geven.
          </p>
          <ButtonWithIcon
            className="buttonPrimaryXL"
            onClick={() => console.log('Nieuwe details toevoegen')}
          >
            Nieuw detail toevoegen
          </ButtonWithIcon>
        </div>
      )}
    </td>
  </tr>
)}


                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DropdownTable;
