import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader/PageHeader';
import BasePage from './Base/BasePage';
import BasicTable from '../components/Table/BasicTable';

const MyPropertiesPage = () => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  
  const fetchData = async () => {
    try {
      const response = await fetch('/data/woningtypes.json');
      const data = await response.json();

      if (data.woningtypes && Array.isArray(data.woningtypes)) {
        setRows(data.woningtypes.map((woningtype) => ({
          title: woningtype.title,
          id: woningtype.id,
          url: `/woningtypes/${woningtype.id}`
        })));
      } else {
        throw new Error('Data structuur is onjuist');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbs = [
    { label: 'Mijn woningtypes', path: '/woningtypes' }
  ];

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <BasePage>
      <PageHeader 
        title="Mijn woningtypes" 
        supportingText="U ziet hier een lijst van al uw woningtypes uit de BIM Database."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        primaryButton={{ label: 'Nieuwe leveringsovereenkomst', iconName: 'add' }}
        secondaryButton={{ label: 'Leverancier uitnodigen', iconName: 'settings' }} 
        showDivider={false}  
      />
      <BasicTable 
        rows={rows} 
        headerTitle="Woningtypes"
        columns={[{ header: 'Woningtypes', field: 'title', type: 'text' }]} // Alleen titel tonen
        searchPlaceholder="Zoek een woningtype..."
        isSortable={false}
        emptyStateConfig={{
          title: 'Geen woningtypes gevonden',
          subtitle: (
            <>
              Het lijkt erop dat u nog geen woningtypes heeft toegevoegd. <br /> Voeg uw eerste woningtype toe om te beginnen.
            </>
          ),
          button: {
            primary: {
              label: 'Woningtype toevoegen',
              onClick: () => console.log('Navigeren naar toevoegen...'), // Pas dit aan naar de gewenste actie
            },
          },
        }}
      />
    </BasePage>
  );
};

export default MyPropertiesPage;
