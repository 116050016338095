import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.css';
import { ReactComponent as HomeIcon } from '../../icons/home-line.svg';
import { ReactComponent as ChevronRightIcon } from '../../icons/chevron-right.svg';
import style from '../../components/Globals/Styles.module.css';

const Breadcrumb = ({ items }) => { // Wijzig 'paths' naar 'items'
  return (
    <div className={styles.breadcrumb}>
      <Link to="/">
        <HomeIcon className={styles.icon} />
      </Link>
      <ChevronRightIcon className={styles.chevron} />
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.current ? (
            <span className={`${styles.current} ${style.textSmSemibold}`}>
              {item.label}
            </span>
          ) : (
            <Link to={item.path} className={`${styles.path} ${style.textSmMedium}`}>
              {item.label}
            </Link>
          )}
          {index !== items.length - 1 && <ChevronRightIcon className={styles.chevron} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
