import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import Accordion from '../../components/Accordion/Accordion';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';
import ProgressSteps from '../../components/ProgressSteps/ProgressSteps';
import style from '../../components/Globals/Styles.module.css';
import styles from './VerkoopContract.module.css';
import Alert from '../../components/Feedback/Alert';

const GegevensControlePage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('formData')) || {};
    setFormData(savedData);
    console.log('Loaded formData:', savedData);
  }, []);

  const breadcrumbs = [
    { label: 'Mijn verkoopcontracten', path: '/verkoopcontracten' },
    { label: 'Nieuw verkoopcontract', path: '/verkoopcontracten/nieuw' },
  ];

  const steps = ['Gegevens invullen', 'Gegevens controleren'];
  const currentStep = 1;

  const handleSubmit = () => {
    // Simulatie van API-verzoek
    const sendDataToAPI = async () => {
      try {
        const response = await fetch('/api/saveContract', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });

        // if (!response.ok) {
        //   throw new Error('API-verzoek mislukt');
        // }

        // console.log('Data succesvol opgeslagen:', formData);

        // Opruimen van de localStorage
        localStorage.removeItem('formData');

        // Navigeren naar de overzichtspagina
        navigate('/verkoopcontracten/succes');
      } catch (error) {
        console.error('Fout bij opslaan:', error);
        alert('Er ging iets mis bij het opslaan. Probeer het opnieuw.');
      }
    };

    sendDataToAPI();
  };

  const handleClick = () => {
  };


  const renderDataRow = (label, value) => (
    <div className={styles.row}>
      <div className={`${style.textMdSemibold} ${styles.title}`}>{label}</div>
      <div className={`${style.textMdRegular} ${styles.value}`}>
        {value || '-'}
      </div>
    </div>
  );

  return (
    <BasePage backgroundColor="#F9FAFB">
      <PageHeader
        title="Concept verkoopcontract opstellen"
        breadcrumbs={breadcrumbs}
        variant="basic"
        showTabs={false}
        showDivider={false}
        extraBottomPadding={true}
      />

      <div className={styles.stepsContainer}>
        <ProgressSteps steps={steps} currentStep={currentStep} />
      </div>

      {/* Blok 1 met formulier type inhoud */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Instructies</h2>
        <p className={style.textSmRegular}>U ziet hier een totaaloverzicht van het concept verkoopcontract die u wil aangaan met de opdrachtgever. Controleer de gegevens goed.De opdrachtgever krijgt de opdracht om de gegevens te controleren en het contract te ondertekenen. U ontvangt een bericht zodra de opdrachtgever akkoord gaat. </p>

        <ButtonWithIcon
          iconName="right"
          iconPosition="left"
          className="alertLink"
          onClick={() => console.log('Link geklikt!')}
        >
          Nog vragen? Kijk hier voor meer informatie
        </ButtonWithIcon>
      </div>

      <Accordion title="Overeenkomstgegevens" grouped={true}>
        {renderDataRow('Overeenkomstnummer', formData.opdrachtNummer)}
        {renderDataRow('Overeenkomst datum', formData.startdatum)}
        {renderDataRow('Type contract',)}
        {renderDataRow('Woningtype', formData.woningtype)}
        {renderDataRow('Aantal woningen', formData.aantalWoningen)}
        {renderDataRow('Assetmanager', formData.beheerder)}
        {renderDataRow('Opdrachtgever', formData.opdrachtgever)}
      </Accordion>

      <Accordion title="Algemene voorwaarden" grouped={false}>
        {renderDataRow('Leveringstermijnen en transport',)}
        {renderDataRow('Kwaliteitseisen',)}
        {renderDataRow('Garantie- en Servicevoorwaarden',)}
        {renderDataRow('Betalingsschema en -voorwaarden',)}
        {renderDataRow('Vertrouwelijkheid en Gegevensbescherming',)}
        {renderDataRow('Aansprakelijkheid en Vrijwaring',)}
        {renderDataRow('Intellectueel Eigendom',)}
        {renderDataRow('Verzekering en dekking',)}
        {renderDataRow('Wijzigingsbeheer',)}
        {renderDataRow('Beëindigingsvoorwaarden',)}
        {renderDataRow('Geschillenbeslechting',)}
        {renderDataRow('Force Majeure', )}
        {renderDataRow('Boeteclausules', )}
        {renderDataRow('Duur van het Contract',)}
        {renderDataRow('Total Cost of Ownership (TCO)',)}
      </Accordion>

      <br />

      <Accordion title="Opdracht informatie" grouped={true}>
        {renderDataRow('Opdracht naam', formData.projectName)}
        {renderDataRow('Budget', formData.projectName)}
        {renderDataRow('Startdatum', formData.projectName)}
        {renderDataRow('Verwachte opleverdatum', formData.projectName)}
      </Accordion>

      <Accordion title="Beheerder informatie (optioneel)" grouped={true}>
        {renderDataRow('Beheerder', formData.projectName)}
        {renderDataRow('Contactpersoon', formData.projectName)}
        {renderDataRow(
          'Adres',
          formData.adres && formData.postcode && formData.stad
            ? <>
              {`${formData.adres || ''} ${formData.huisnummer || ''}${formData.toevoeging ? ` ${formData.toevoeging}` : ''}`}<br />
              {`${formData.postcode || ''}, ${formData.stad || ''}`}<br />
              {formData.land || ''}
            </>
            : '-'
        )}



        {renderDataRow('Telefoonnummer', formData.projectName)}
        {renderDataRow('Email', formData.projectName)}
      </Accordion>

      <Accordion title="Bouwlocatie" grouped={true}>
        {renderDataRow('Bedrijfsnaam', formData.projectName)}
        {renderDataRow(
          'Adres',
          formData.adres && formData.postcode && formData.stad
            ? <>
              {`${formData.adres || ''} ${formData.huisnummer || ''}${formData.toevoeging ? ` ${formData.toevoeging}` : ''}`}<br />
              {`${formData.postcode || ''}, ${formData.stad || ''}`}<br />
              {formData.land || ''}
            </>
            : '-'
        )}
      </Accordion>

      <Accordion title="Assemblage locatie" grouped={true}>
        {renderDataRow('Bedrijfsnaam', formData.projectName)}
        {renderDataRow(
          'Adres',
          formData.adres && formData.postcode && formData.stad
            ? <>
              {`${formData.adres || ''} ${formData.huisnummer || ''}${formData.toevoeging ? ` ${formData.toevoeging}` : ''}`}<br />
              {`${formData.postcode || ''}, ${formData.stad || ''}`}<br />
              {formData.land || ''}
            </>
            : '-'
        )}
      </Accordion>

      <Accordion title="Woningspecificaties" grouped={true}>
        {renderDataRow('Optie voor de woning', formData.projectName)}
      </Accordion>

      <Accordion title="Overige informatie" grouped={true}>
        {renderDataRow('Opmerkingen', formData.projectName)}
        {renderDataRow('Risicoanalyse', formData.projectName)}


      </Accordion>

      <Accordion title="Documenten" grouped={false}>
        {renderDataRow('Projectplan', formData.documents?.projectPlan?.map(file => file.name).join(', '))}
        {renderDataRow('Bouwplan', formData.documents?.constructionPlan?.map(file => file.name).join(', '))}
        {renderDataRow('Vergunningen', formData.documents?.permits?.map(file => file.name).join(', '))}
        {renderDataRow('Risicoanalyse', formData.documents?.risicoanalyse?.map(file => file.name).join(', '))}
      </Accordion>


      <br />

      <Accordion title="Ondertekening" grouped={false}>
        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdrachtgever</label>
          </div>
          <div className={styles.inputColumn}>
            <Alert
              type="info"
              title="In afwachting van opdrachtgever"
              description="Het verkoopcontract wordt verstuurd naar de opdrachtgever voor ondertekening."
            />
          </div>
        </div>
      </Accordion>

      <div className={styles.controleButtonContainer}>
        <ButtonWithIcon
          className="buttonSecondaryGray2XL"
          onClick={() => navigate(-1)}
        >
          Vorige
        </ButtonWithIcon>

        <ButtonWithIcon
          className="buttonPrimary2XL"
          onClick={handleSubmit}
        >
          Opslaan en versturen
        </ButtonWithIcon>

      </div>
    </BasePage>
  );
};

export default GegevensControlePage;
