import React from 'react';

const UnauthorizedPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h1>403 - Geen Toegang</h1>
      <p>U heeft geen rechten om deze pagina te bekijken.</p>
    </div>
  );
};

export default UnauthorizedPage;
