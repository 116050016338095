import React from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';

const SuccessPage = () => {
  const navigate = useNavigate();

  const handleGoToOverview = () => {
    navigate('/verkoopcontracten');
  };

  const breadcrumbs = [
    { label: 'Mijn verkoopcontracten', path: '' },
    { label: 'Nieuw verkoopcontract', path: '' },
    { label: 'Succesvol verstuurd', path: '' },
  ];

  return (
    <BasePage>
      <PageHeader
        variant="basic"
        title="Het concept verkoopcontract is succesvol verstuurd"
        supportingText="U heeft succesvol een concept verkoopcontract verstuurd naar de opdrachtgever. De opdrachtgever krijgt de opdracht om de gegevens te controleren en akkoord te gaan met het verkoopcontract. U ontvangt een bericht zodra de opdrachtgever akkoord gaat."
        breadcrumbs={breadcrumbs}
        showTabs={false}
        showDivider={false}
        extraBottomPadding={false}
      />
      <div style={{ marginTop: '24px', textAlign: 'center' }}>
      <ButtonWithIcon
          iconName="right"
          iconPosition="left"
          className="alertLink"
          onClick={handleGoToOverview}
        >
          Terug naar overzicht
        </ButtonWithIcon>
      </div>
    </BasePage>
  );
};

export default SuccessPage;
