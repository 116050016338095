import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';
import BasicTable from '../../components/Table/BasicTable';

const VerkoopContractenPage = () => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch('/data/verkoopcontracten.json');
      const data = await response.json();

      if (data.projects && Array.isArray(data.projects)) {
        setRows(data.projects.map((project) => ({
          ...project,
          url: `/verkoopcontracten/${project.projectNumber}`
        })));
      } else {
        setRows([]);
        setError(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setRows([]);
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbs = [
    { label: 'Mijn verkoopcontracten', path: '/verkoopcontracten' }
  ];

  return (
    <BasePage>
      <PageHeader 
        title="Mijn verkoopcontracten"  
        supportingText="Hier kunt u eenvoudig uw verkoopcontracten met opdrachtgevers beheren, bekijken en afsluiten. U kunt voor een verkoopcontract ook de benodigde bestellingen plaatsen en inzien."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        primaryButton={{ 
          label: 'Nieuw verkoopcontract', 
          onClick: () => navigate('/verkoopcontracten/nieuw')
        }}
        showDivider={false}  
      />

<BasicTable
  searchPlaceholder="Zoek verkoopcontract of contractnummer"
  rows={rows}
  columns={[
    { header: 'Verkoopcontract met opdrachtgever', field: 'name', type: 'text', textColor: '#101828',width: '26%' },
    { header: 'Contractnummer', field: 'projectNumber', type: 'text', textColor: '#667085', width: '10%' },
    { header: 'Bouwlocatie', field: 'location', type: 'text', textColor: '#667085',width: '10%' },
    { header: 'Startdatum', field: 'startDate', type: 'date', textColor: '#667085', width: '12%' },
    { header: 'Verwachte opleverdatum', field: 'expectedCompletionDate', type: 'date', textColor: '#667085', width: '14%' },
    { header: 'Status contract', field: 'status', type: 'badge', width: '20%' },
  ]}
  error={error}
  isSortable={false}
  emptyStateConfig={{
    title: 'Geen verkoopcontracten gevonden',
    subtitle: (
      <>
        U hebt nog geen verkoopcontracten in uw lijst. <br /> Voeg een nieuwe verkoopcontract toe.
      </>
    ),
    button: {
      primary: {
        label: 'Nieuwe verkoopcontract',
        onClick: () => navigate('/verkoopcontracten/nieuw'),
      },
    },
  }}
/>

    </BasePage>
  );
};

export default VerkoopContractenPage;
