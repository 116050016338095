import React from 'react';
import styles from './ButtonTextIcon.module.css'; 
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import { ReactComponent as ArrowRight } from '../../icons/chevron-right.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter-lines.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload-01.svg';

const IconSelector = ({ name }) => {
    const icons = {
        circle: CircleIcon,
        right: ArrowRight,
        filter: FilterIcon,
        upload: UploadIcon,
    };
    const IconComponent = icons[name];
    return IconComponent ? <IconComponent /> : null;
};

const ButtonWithIcon = ({ children, iconName, iconPosition = 'left', className, onClick }) => {
    const buttonClass = styles[className] || styles.buttonUpload;

    return (
        <button className={buttonClass} onClick={onClick}>
            {(iconName && (iconPosition === 'left' || iconPosition === 'both')) && (
                <span className={styles.iconLeft}><IconSelector name={iconName} /></span>
            )}
            {children}
            {(iconName && (iconPosition === 'right' || iconPosition === 'both')) && (
                <span className={styles.iconRight}><IconSelector name={iconName} /></span>
            )}
        </button>
    );
};

export default ButtonWithIcon;
