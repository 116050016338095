import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import HomePage from './pages/Home/HomePage';
import OvereenkomstenPage from './pages/OvereenkomstenPage';
import ContactPage from './pages/Contact/ContactPage';
import MyPropertiesPage from './pages/MyPropertiesPage';
import WoningtypeDetailPage from './pages/WoningtypeDetailPage';

import OverzichtVerkoopContract from './pages/verkoopcontracten/OverzichtVerkoopContract';
import VerkoopContractDetailPage from './pages/verkoopcontracten/VerkoopContractDetailPage';
import NieuwContractPage from './pages/verkoopcontracten/NieuwVerkoopContract';
import GegevensControlePage from './pages/verkoopcontracten/ControleVerkoopContract';
import SuccessPage from './pages/verkoopcontracten/SuccesVerkoopContract';

import LoginPage from './pages/Login/LoginPage';
import AccessDeniedPage from './pages/Errors/AccessDeniedPage';
import NotFoundPage from './pages/Errors/NotFoundPage';

import './components/Globals/foundation.css';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Publieke routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/unauthorized" element={<AccessDeniedPage />} />

          {/* Beveiligde routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute allowedRoles={['Opdrachtgever', 'Leverancier', 'Assetmanager']}>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/woningtypes"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <MyPropertiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/woningtypes/:id"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <WoningtypeDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verkoopcontracten"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <OverzichtVerkoopContract />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verkoopcontracten/:id"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <VerkoopContractDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leveringsovereenkomsten"
            element={
              <ProtectedRoute allowedRoles={['Leverancier', 'Assetmanager']}>
                <OvereenkomstenPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoute allowedRoles={['Opdrachtgever', 'Leverancier', 'Assetmanager']}>
                <ContactPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verkoopcontracten/nieuw"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <NieuwContractPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verkoopcontracten/nieuw/controle"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <GegevensControlePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verkoopcontracten/succes"
            element={
              <ProtectedRoute allowedRoles={['Assetmanager']}>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          
          {/* Fallback route voor niet-bestaande paden */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
