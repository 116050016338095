import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../components/Globals/Styles.module.css';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const validCredentials = {
    username: 'tester',
    password: 'dmp2024!',
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (username === validCredentials.username && password === validCredentials.password) {
      setError('');
      setIsLoggedIn(true); // Markeer als ingelogd
    } else {
      setError('Ongeldige gebruikersnaam of wachtwoord');
    }
  };

  const handleRoleSelection = (role) => {
    localStorage.setItem('role', role); // Rol opslaan
    navigate('/'); // Navigeren naar de homepagina
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h1 className={style.displayLgSemibold}>Login</h1>
      
      {!isLoggedIn ? (
        <form onSubmit={handleLogin} style={{ maxWidth: '400px', margin: '0 auto' }}>
          <label>
            Gebruikersnaam:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              style={{ display: 'block', width: '100%', marginBottom: '16px' }}
            />
          </label>
          <label>
            Wachtwoord:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ display: 'block', width: '100%', marginBottom: '16px' }}
            />
          </label>
          <button type="submit" className="buttonPrimary2XL" style={{ width: '100%' }}>
            Login
          </button>
        </form>
      ) : (
        <div>
          <p className={style.textXlRegular}>Kies een rol om verder te gaan:</p>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '24px' }}>
            <ButtonWithIcon
              className="buttonPrimary2XL"
              onClick={() => handleRoleSelection('Opdrachtgever')}
            >
              Login als OG
            </ButtonWithIcon>
            <ButtonWithIcon
              className="buttonPrimary2XL"
              onClick={() => handleRoleSelection('Leverancier')}
            >
              Login als LV
            </ButtonWithIcon>
            <ButtonWithIcon
              className="buttonPrimary2XL"
              onClick={() => handleRoleSelection('Assetmanager')}
            >
              Login als AM
            </ButtonWithIcon>
          </div>
        </div>
      )}

      {error && <p style={{ color: 'red', marginTop: '16px' }}>{error}</p>}
    </div>
  );
};

export default LoginPage;
