import React from 'react';
import styles from './Alert.module.css';
import style from '../Globals/Styles.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { ReactComponent as InfoIcon } from '../../icons/info-circle.svg';
import { ReactComponent as SuccessIcon } from '../../icons/check-circle.svg';



const alertConfig = {
  info: {
    icon: InfoIcon,
    backgroundColor: 'var(--color-info-icon)',
    iconBgColor: 'var(--color-info)',
  },
  success: {
    icon: SuccessIcon,
    backgroundColor: 'var(--color-success)',
    iconBgColor: 'var(--color-success-icon)',
  },
  warning: {
    icon: InfoIcon,
    backgroundColor: 'var(--color-warning)',
    iconBgColor: 'var(--color-warning-icon)',
  },
  error: {
    icon: InfoIcon,
    backgroundColor: 'var(--color-error)',
    iconBgColor: 'var(--color-error-icon)',
  },
};

const Alert = ({ type = 'info', title, description, linkText, linkUrl, onClick }) => {
  const config = alertConfig[type] || alertConfig.info;

  return (
    <div className={styles.alert}>
      <div
        className={styles.icon}
        style={{
          backgroundColor: config.iconBgColor,
          color: config.backgroundColor,
        }}
      >
        <config.icon />
      </div>
      <div className={styles.content}>
        <p
          className={style.textSmSemibold}
          style={{ color: 'var(--color-alert-title)' }}
        >
          {title}
        </p>
        <p
          className={style.textSmRegular}
          style={{ color: 'var(--color-alert-description)' }}
        >
          {description}
        </p>
        {linkText && (
          <ButtonWithIcon
            iconName="right"
            iconPosition="left"
            className="alertLink"
            onClick={(e) => {
              e.preventDefault();
              onClick();
              if (linkUrl) window.location.href = linkUrl;
            }}
          >
            {linkText}
          </ButtonWithIcon>
        )}
      </div>
    </div>
  );
};

export default Alert;
