import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './PageHeader.module.css';
import style from '../Globals/Styles.module.css';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import HorizontalTabs from '../Tabs/HorizontalTabs';

const PageHeader = ({ 
  title, 
  supportingText, 
  breadcrumbs = [],  // Standaardwaarde toegevoegd
  primaryButton,
  secondaryButton,
  showBreadcrumb = true, // Nieuwe prop toegevoegd om breadcrumb te tonen of te verbergen
  showTabs = true, 
  tabs = [], 
  onTabChange,   
  variant = 'default',
  showDivider = true,
  extraBottomPadding = false 
}) => {
  return (
    <div 
      className={`${variant === 'basic' ? styles.containerBasic : styles.container} ${extraBottomPadding ? styles.extraPaddingBottom : ''}`}
    >
      <Helmet>
        <title>{title} - DataMarketPlace</title>
      </Helmet>

      {showBreadcrumb && <Breadcrumb items={breadcrumbs} />} {/* Breadcrumb wordt getoond of verborgen */}

      <div className={styles.headerTop}>
        <div className={styles.titleBlock}>
          <h1 className={`${styles.title} ${style.displaySmBold}`}>{title}</h1>
          <p className={`${styles.supportingText} ${style.textMdRegular}`}>{supportingText}</p>
          
          {showTabs && tabs.length > 0 && (
            <div className={`${styles.tabStyle}`}>
              <HorizontalTabs 
                tabs={tabs} 
                variant="variant1" 
                onTabChange={onTabChange}
              />
            </div>
          )}
        </div>
        
        <div className={styles.buttonGroup}>
          {secondaryButton && (
            <ButtonWithIcon 
              className="buttonSecondaryGrayXL" 
              iconName={secondaryButton.iconName || ""}
              onClick={secondaryButton.onClick}>
              {secondaryButton.label}
            </ButtonWithIcon>
          )}
          {primaryButton && (
            <ButtonWithIcon 
              className="buttonPrimaryXL" 
              iconName={primaryButton.iconName || ""}
              onClick={primaryButton.onClick}>
              {primaryButton.label}
            </ButtonWithIcon>
          )}
        </div>
      </div>

      {showDivider && <hr className={styles.divider} />}
    </div>
  );
};

export default PageHeader;
