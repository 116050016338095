import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Checkbox.module.css'; 
import style from '../Globals/Styles.module.css'; 

const Checkbox = ({ label, linkLabel, linkPath, checked, onChange, disabled }) => {
  const uniqueId = `checkbox-${Math.random().toString(36).substr(2, 9)}`; // Unieke ID voor koppeling tussen label en input
  
  return (
    <div className={`${styles.checkboxContainer} ${disabled ? styles.disabled : ''}`}>
      <input 
        id={uniqueId}
        type="checkbox" 
        className={styles.checkboxInput}
        checked={checked} 
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={uniqueId} className={`${styles.checkboxLabel} ${style.textMdRegular}`}>
        <span className={styles.checkmark}></span>
        {label}{' '}
        {linkLabel && linkPath && (
          <Link to={linkPath} className={styles.link}>
            {linkLabel}
          </Link>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
