import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './HeaderNavigation.module.css';
import style from '../Globals/Styles.module.css';
import logo from '../../assets/images/LogoHeader.webp';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { ReactComponent as UserIcon } from '../../icons/user-01.svg'; // Controleer pad

const HeaderNavigation = ({ menuItems, onMenuClick }) => {
  const navigate = useNavigate();

  // Haal de huidige rol op uit localStorage
  const role = localStorage.getItem('role') || 'Log in';

  // Functie om uit te loggen
  const handleLogout = () => {
    localStorage.removeItem('role'); // Verwijder de rol
    navigate('/login'); // Stuur de gebruiker naar de loginpagina
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {/* Klikbaar logo */}
        <Link to="/">
          <img src={logo} alt="Go to Homepage" className={styles.logo} />
        </Link>
        <div className={styles.buttonGroup}>
          {/* Profielfoto en rolnaam samen */}
          <div className={styles.buttonWithIconGroup}>
            <div className={styles.profileCircle}>
              <UserIcon className={styles.profileIcon} />
            </div>
            <ButtonWithIcon className="buttonTetiaryGrayXL">
              {role}
            </ButtonWithIcon>
          </div>
          {/* Uitlogknop */}
          <ButtonWithIcon
            className="buttonSecondaryGrayXL"
            onClick={handleLogout}
          >
            Uitloggen
          </ButtonWithIcon>
        </div>
      </div>
      <div className={styles.fullWidthLine}></div> {/* Lijn tussen logo-container en navigatiebalk */}
      <div className={styles.navBar}>
        <nav className={styles.navMenu}>
          {menuItems.map((item, index) => (
            <ButtonWithIcon
              key={index}
              className="buttonTetiaryGrayXL"
              onClick={() => onMenuClick(item)}
            >
              {item.label}
            </ButtonWithIcon>
          ))}
        </nav>
      </div>
      <div className={styles.fullWidthLine}></div> {/* Onderste lijn over volledige breedte */}
    </div>
  );
};

export default HeaderNavigation;
