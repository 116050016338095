import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation/HeaderNavigation';
import Footer from '../../components/Footer/Footer'; 
import styles from '../Base/BasePage.module.css'; 

const BasePage = ({ children, backgroundColor }) => {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // Haal de rol op uit localStorage
    const role = localStorage.getItem('role');

    // Stel menu-items in op basis van de rol
    switch (role) {
      case 'Opdrachtgever':
        setMenuItems([
          { label: 'Mijn verkoopovereenkomsten' },
          { label: 'Service & Contact' },
        ]);
        break;
      case 'Assetmanager':
        setMenuItems([
          { label: 'Mijn woningtypes' },
          { label: 'Mijn verkoopcontracten' },
          { label: 'Mijn leveringsovereenkomsten' },
          { label: 'Service & Contact' },
        ]);
        break;
      case 'Leverancier':
        setMenuItems([
          { label: 'Mijn producten' },
          { label: 'Mijn bestellingen' },
          { label: 'Mijn leveringsovereenkomsten' },
          { label: 'Service & Contact' },
        ]);
        break;
      default:
        setMenuItems([]); // Geen items voor onbekende rollen
        break;
    }
  }, []);

  const handleMenuClick = (item) => {
    switch (item.label) {
      case 'Mijn verkoopovereenkomsten':
        navigate('/verkoopovereenkomsten');
        break;
      case 'Mijn woningtypes':
        navigate('/woningtypes');
        break;
      case 'Mijn verkoopcontracten':
        navigate('/verkoopcontracten');
        break;
      case 'Mijn leveringsovereenkomsten':
        navigate('/leveringsovereenkomsten');
        break;
      case 'Mijn producten':
        navigate('/producten');
        break;
      case 'Mijn bestellingen':
        navigate('/bestellingen');
        break;
      case 'Service & Contact':
        navigate('/contact');
        break;
      default:
        navigate('/'); 
    }
  };

  // Als `children` geen array is, maak er een array van
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles.pageContainer}>
      <HeaderNavigation 
        menuItems={menuItems} 
        onMenuClick={handleMenuClick} 
      />
      
      {/* Witte achtergrond voor de PageHeader */}
      <div className={styles.headerWrapper}>
        {childrenArray[0]}
      </div>

      {/* Achtergrondkleur voor alles onder de PageHeader */}
      <div className={styles.backgroundWrapper} style={{ backgroundColor: backgroundColor || 'inherit' }}>
        <main className={styles.mainContent}>
          {childrenArray.slice(1)} {/* Render de rest van de kinderen */}
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default BasePage;
