import React from 'react';
import styles from './InputField.module.css';
import style from '../Globals/Styles.module.css';
import { ReactComponent as HelpIcon } from '../../icons/help-circle.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';

const InputField = ({
  label,
  hint,
  placeholder = "Enter text",
  error,
  disabled = false,
  help = false,
  isTextarea = false, // Nieuw: maakt het mogelijk een textarea te renderen
  rows = 5, // Nieuw: aantal rijen voor de textarea
  value, // Gecontroleerde waarde
  onChange, // Functie om wijzigingen door te geven
}) => {
  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={`${styles.labelCustom} ${style.textSmMedium}`}>
          {label}
        </label>
      )}
      <div className={`${styles.inputWrapper} ${error ? styles.error : ''}`}>
        {isTextarea ? (
          <textarea
            className={`${styles.input} ${style.textMdRegular}`}
            placeholder={placeholder}
            rows={rows}
            value={value} // Gecontroleerde waarde
            onChange={onChange} // Doorgeven van wijzigingen
            disabled={disabled}
          />
        ) : (
          <input
            type="text"
            className={`${styles.input} ${style.textMdRegular}`}
            placeholder={placeholder}
            value={value} // Gecontroleerde waarde
            onChange={onChange} // Doorgeven van wijzigingen
            disabled={disabled}
          />
        )}
        {help && !error && <HelpIcon className={`${styles.icon} ${styles.helpIcon}`} />}
        {error && <AlertIcon className={`${styles.icon} ${styles.alertIcon}`} />}
      </div>
      {error ? (
        <p className={`${styles.hintCustom} ${style.textSmRegular} ${styles.errorText}`}>{error}</p>
      ) : (
        <p className={`${styles.hintCustom} ${style.textSmRegular}`}>{hint}</p>
      )}
    </div>
  );
};

export default InputField;
