import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Pagina niet gevonden</h1>
      <p>De pagina die u zoekt bestaat niet.</p>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <ButtonWithIcon
          className="buttonPrimary2XL"
          onClick={() => navigate('/')}
        >
          Terug naar Home
        </ButtonWithIcon>
      </div>
    </div>
  );
};

export default NotFoundPage;
