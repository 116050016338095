import React, { useState } from 'react';
import styles from './HorizontalTabs.module.css';
import style from '../Globals/Styles.module.css';

const HorizontalTabs = ({ tabs, variant, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleTabClick = (key) => {
    setActiveTab(key);
    if (onTabChange) {
      onTabChange(key); // Roep de onTabChange-functie aan als een nieuwe tab wordt geselecteerd
    }
  };

  const tabClass = (key) => {
    return key === activeTab ? `${styles.active} ${style.textMdSemibold}` : `${styles.inactive} ${style.textMdSemibold}`;
  };

  const tabStyle = variant === 'variant1' ? styles.variant1 : styles.variant2;

  return (
    <div className={styles.tabsContainer}>
      {tabs.map(tab => (
        <button
          key={tab.key}
          className={`${styles.tab} ${tabClass(tab.key)} ${tabStyle}`}
          onClick={() => handleTabClick(tab.key)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default HorizontalTabs;
