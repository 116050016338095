import React, { useState, useRef } from 'react';
import styles from './SearchField.module.css'; 
import style from '../Globals/Styles.module.css';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';

const SearchField = ({ label, hint, placeholder = "Search", onSearch }) => { // Voeg onSearch toe als prop
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(""); 
  const inputRef = useRef(null);

  const getTextStyle = () => {
    if (inputValue) {
      return `${styles.input} ${style.textMdMedium}`;
    }
    return `${styles.input} ${style.textMdRegular}`;
  };

  return (
    <div className={styles.searchContainer} ref={inputRef}>
      {label && <label className={`${styles.labelCustom} ${style.textSmSemibold}`}>{label}</label>}
      <div className={`${styles.search} ${isFocused ? styles.focused : ''}`}>
        <SearchIcon className={styles.icon} />
        <input
          className={getTextStyle()} 
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => {
            setInputValue(e.target.value); // Update de interne waarde
            onSearch(e.target.value); // Roep onSearch aan en stuur de waarde door naar de oudercomponent
          }} 
          value={inputValue}
        />
      </div>
      {hint && <p className={`${styles.hintCustom} ${style.textSmRegular}`}>{hint}</p>}
    </div>
  );
};

export default SearchField;
