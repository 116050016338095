import React from 'react';
import BasePage from '../Base/BasePage';
import PageHeader from '../../components/PageHeader/PageHeader';

const HomePage = () => {
  // Haal de rol op uit localStorage
  const role = localStorage.getItem('role');

  // Bepaal de titel op basis van de rol
  const getTitleForRole = () => {
    switch (role) {
      case 'Opdrachtgever':
        return 'Welkom Opdrachtgever';
      case 'Leverancier':
        return 'Welkom Leverancier';
      case 'Assetmanager':
        return 'Welkom Assetmanager';
      default:
        return 'Welkom Gebruiker';
    }
  };

  const breadcrumbs = [
    { label: 'Home', path: '/' }
  ];

  return (
    <BasePage>
      <PageHeader 
        title={getTitleForRole()}
        supportingText="U ziet hier de homepage."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}  
      />
    </BasePage>
  );
};

export default HomePage;
