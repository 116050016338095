import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader/PageHeader';
import BasePage from './Base/BasePage';
import DropdownTable from '../components/Table/DropdownTable';
import { useParams } from 'react-router-dom';

const WoningtypeDetailPage = () => {
  const { id } = useParams();
  const woningtype = `[Naam woningtype] - [Referentienummer ${id}]`;

  const breadcrumbs = [
    { label: 'Mijn woningtypes', path: '/woningtypes' },
    { label: woningtype, path: `/woningtypes/${id}` }
  ];

  const radioOptions = [
    { label: 'Toon alles', value: 'all' },
    { label: 'Toon alleen de lege IFC specificaties', value: 'emptyIFC' } 
  ];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/woningtypes.json');
        const data = await response.json();
        const woningtypeData = data.woningtypes.find(wt => wt.id === parseInt(id));
        setRows(woningtypeData ? woningtypeData.overeenkomsten : []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const filterRows = () => {
    if (selectedRadio === 'emptyIFC') {
      // Filter voor alleen lege overeenkomsten
      return rows.filter(row => row.details.length === 0);
    }
    // Toon alles wanneer 'Toon alles' is geselecteerd
    return rows;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BasePage backgroundColor="#F9FAFB">
      <PageHeader
        title={woningtype}
        breadcrumbs={breadcrumbs}
        variant="basic"
        showTabs={false}
        primaryButton={{ label: 'Nieuwe leveringsovereenkomst', iconName: 'add' }}
        secondaryButton={{ label: 'Leverancier uitnodigen', iconName: 'settings' }}
        showDivider={false}
        extraBottomPadding={true}
      />
      
      {/* Voeg de radiobutton-functionaliteit toe aan de DropdownTable */}
      <DropdownTable 
        rows={filterRows()}
        error={false} 
        showRadioButtons={true} 
        radioOptions={radioOptions} 
        selectedRadio={selectedRadio}  
        onRadioChange={setSelectedRadio}  
        searchPlaceholder="Zoek een leverancier of IFC component..." 
      />
    </BasePage>
  );
};

export default WoningtypeDetailPage;
